<template>
    <div class="order_search flex">
        <img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
        <el-input class="order_search_inp" v-model="title" placeholder="输入标题/订单号"
            @keyup.enter.native="change_text"></el-input>
        <button class="search_but" @click="change_text">搜索</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
        }
    },
    methods: {
        change_text() {
            this.$emit('search_text', this.title)
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/MediaOrder';
</style>