<template>
	<div>
		<div class="box" v-loading.fullscreen.lock="fullscreenLoading">
			<Title title="我的工单" />
			<div class="ct_box">
				<div class="search_status_box flex">
					<div class="flex">
						<order_time @get_time="get_time" />
						<order_search @change_text="change_text" />
						<el-select v-model="my_place_order.category_id" @change="pageChange(1)" placeholder="请选择媒体类别">
							<el-option v-for="item in category_id_list" :key="item.id" :value="item.id"
								:label="item.title">
							</el-option>
						</el-select>
					</div>
					<placard_order_status type="order_status_num" @status_tab="status_tab"
						:order_status="my_place_order.order_status" :status_list="status_list" width="250" />
				</div>
				<el-table :data="tableData" style="width: 100%">
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="sn" label="订单ID" width="150">
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="title" label="标题" width="200">
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="desc" label="问题描述" width="430">
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="category_title" label="广告类型" width="200">
						<template slot-scope="scope">
							{{ category_id_list[scope.row.category_id].title }}
						</template>
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="order_price" label="金额" width="200">
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" label="状态" width="200">
						<template slot-scope="scope">
							<span :class="status_list[scope.row.status + 1].class" :style="{  color: status_list[scope.row.status + 1].color }">
								{{ status_list[scope.row.status + 1].title }}</span>
						</template>
					</el-table-column>
					<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="ctime" label="提交时间" width="200">
					</el-table-column>
				</el-table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>

	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import order_time from '@/components/order_time'
import order_search from '@/components/order_search'
import placard_order_status from '@/components/placard_order_status'
import { timeCut } from '@/util/timeCut'
import { mapState } from 'vuex'
export default {
	components: {
		Title,
		order_time,
		order_search,
		placard_order_status
	},
	computed: {
		...mapState(['placard'])
	},
	mounted() {
		if (this.$route.query.status && this.$route.query.status == '已处理') {
			this.status.id = {
				id: 1,
				title: '已处理',
			}
		}
		this.curlGet('/api/guanggao_info/init').then(res => {
			if (res.data.code) {
				this.category_id_list = res.data.data
			}
		})
		this.get_list()
	},
	data() {
		return {
			category_id_list: [],
			status_list: [{
				id: '',
				title: '全部',

			}, {
				id: 0,
				title: '未处理',
				color: 'rgba(51, 51, 51, 1)',
				class: 'untreated'
			}, {
				id: 1,
				title: '已处理',
				color: 'rgba(57, 204, 12, 1)',
				class: 'untreated_success'
			},
			{
				id: 2,
				title: '拒绝处理',
				color: 'rgba(224, 25, 25, 1)',
				class: 'untreated_success'
			}],
			// 我的工单
			my_place_order: {
				title: '',
				time: [],
				category_id: '',
				order_status: '',
			},
			tableData: [],
			total_page: 0, //分页总页数
			page: 1,
			count: 0,
			fullscreenLoading: false, //loding
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.get_list()
		},
		get_time(time) {
			this.my_place_order.time = time || []
			this.pageChange(1)
		},
		change_text(text) {
			this.my_place_order.title = text
			this.pageChange(1)
		},
		status_tab(id) {
			this.my_place_order.order_status = id
			this.pageChange(1)
		},
		LinkShow() {
			this.Link = true
		},
		wordOrderShow() {
			this.WorkOrder = true
		},
		get_list() {
			this.fullscreenLoading = true
			// 取媒体类别
			let data = {}
			data.page = this.page
			data.limit = 20
			if (this.my_place_order.order_status !== '') {
				data.status = this.my_place_order.order_status
			}
			if (this.my_place_order.time.length) {
				data.start_time = this.my_place_order.time[0] / 1000;
				data.end_time = this.my_place_order.time[1] / 1000;
			}
			if (this.my_place_order.category_id !== '') {
				data.category_id = this.my_place_order.category_id
			}
			this.curlGet('/api/users/guanggao_work/index', data).then(res => {
				if (res.data.code) {
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData = res.data.data.list
					this.tableData.forEach((item, index) => {
						this.tableData[index].createtime = timeCut(this.tableData[index].createtime * 1000)
					})
					this.fullscreenLoading = false
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/MediaOrder';
@import '@/scss/placard_media';

::v-deep .el-input__inner {
	height: 30px;
}

::v-deep .el-input__icon {
	line-height: 30px;
}
</style>